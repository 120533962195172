import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import logo from "../img/logo.svg";

function PaymentPage({ plan }) {
  let value = 97,
    grandTotal = "8050/-  ( $ 97 )";
  if (plan == "Standard") {
    value = 147;
    grandTotal = "12,200/- ( $ 147 )";
  } else if (plan == "Pro") {
    value = 297;
    grandTotal = "24,650/-  ( $ 297 )";
  }

  const initialOptions = {
    clientId:
      "AaX2KvJrGP9mZePH_CuTNSIhbJO3bCtxA0lSaCQ0mnlUaeePjvrkOGFzKVMxMp64vE27EVBofEcWMwMb",
    currency: "USD",
    intent: "capture",
  };

  // const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
  // const serverUrl = "https://sellerkinofficialwebapp.azurewebsites.net/paypal";
  // const serverUrl = "https://sellerkinofficialwebapp.azurewebsites.net/paypal";
  const createOrder = async (data) => {
    // Order is created on the server and the order id is returned
    return fetch(
      `https://sellerkinofficialwebappfrontendfunctionapp.azurewebsites.net/api/orders?code=ahXFumiZ9Jzz87DNnlpPF36SK1o88gy30QVKJqqT2i0RAzFuvkGWwA==`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // use the "body" param to optionally pass additional order information
        // like product skus and quantities
        body: JSON.stringify({
          cart: [
            {
              sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
              quantity: "YOUR_PRODUCT_QUANTITY",
              value: value,
            },
          ],
        }),
      }
    )
      .then((response) => response.json())
      .then((order) => order.id);
  };
  const onApprove = async (data) => {
    // Order is captured on the server and the response is returned to the browser
    return fetch(
      `https://sellerkinofficialwebappfrontendfunctionapp.azurewebsites.net/api/orders/capture?code=qwn0gFt_BxrvhRvoEsKF53AMVJJH86Vdi5Jvj1F4ezloAzFuiLEOJQ==`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
        }),
      }
    ).then((response) => response.json());
  };

  return (
    <div className="bg-custom-color h-[100vh] pt-10">
      <div className="w-10/12 md:w-4/12 mx-auto flex flex-col gap-10 p-10  bg-orange-100 rounded shadow-lg">
        <div className="flex text-xl font-semibold justify-center items-end ">
          <img src={logo} className="h-10" />
          <span>Sellerkin</span>
        </div>
        <div className="  font-semibold text-lg bg-white py-4 w-8/12 mx-auto px-4 rounded-lg">
          <div className="flex justify-between border-b border-gray-300 w-12/12 mx-auto pb-2">
            <p>Plan :</p>
            <p>{plan}</p>
          </div>
          <div className="flex justify-between  items-center w-12/12 mx-auto pt-3">
            <p>Grand Total :</p>
            <p className="text-sm">&#8377; {grandTotal}</p>
          </div>
        </div>
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            createOrder={(data) => createOrder(data)}
            onApprove={(data) => onApprove(data)}
            //   style={{
            //     layout: "horizontal",
            //     tagline: false,
            //     height: 40,
            //   }}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  );
}

export default PaymentPage;
