import React from "react";
import book from "../img/Component 8.webp";
import book1 from "../img/Component 34.webp";
import visa1 from "../img/toppng 4.svg";
import secure from "../img/lock1.svg";
import days from "../img/7days1.svg";
import small_right from "../img/mingcute_arrows-right-line (1).svg";
import small_left from "../img/Group.webp";
import { Link } from "react-scroll";
const Fourteen = () => {
  return (
    <div className="bg-custom-color-2 md:pt-20 pt-10">
      <div className="md:grid  hidden grid-cols-2">
        <div className="font-serif xl:text-xl md:text-xs flex flex-col gap-7 xl:ml-28 md:ml-20 ">
          <div>
            <p>After all, why wait another decade for results?</p>
          </div>
          <div>
            <p>Seize the opportunity now with Sellerkin, and</p>
            <p>
              <strong>just a week from today,</strong> you'll witness the{" "}
            </p>
            <p>
              <strong>transformation </strong> you've been longing for.
            </p>
          </div>
          <div>
            <p>Your dedication will shine as you uncover</p>
            <p>
              beloved Etsy products, <strong>making informed</strong>
            </p>
            <p>
              <strong>decisions </strong> &nbsp; that align with your passion
            </p>
          </div>
          <div>
            <p>Your journey to success is </p>
            <p>underway, and it all starts today.</p>
          </div>
          <Link
            to="pricing_table"
            spy={true}
            smooth={true}
            offset={20}
            duration={1000}
            className="cursor-pointer bg-custom-color-1  xl:text-2xl md:text-sm xl:p-2 md:p-3  border-2 rounded-lg   text-white w-10/12 xl:w-10/12"
            style={{ transition: "all 0.3s" }}
          >
            <p className="flex justify-center items-center">
              Get instant Access to Sellerkin
            </p>
          </Link>

          <div className="md:flex  gap-4 md:max-w-[20rem] xl:max-w-[24rem] ">
            <div className="flex justify-center mb-5">
              <img
                loading="lazy"
                src={visa1}
                alt="payment"
                className=" xl:w-64 md:w-36 "
              ></img>
            </div>
            <div className="flex justify-center mb-5 gap-3">
              <img
                loading="lazy"
                src={secure}
                alt="payment"
                className="relative md:w-16 xl:w-24 mb-2"
              ></img>
              <img
                loading="lazy"
                src={days}
                alt="payment"
                className="relative md:w-16  xl:w-24 mb-2"
              ></img>
            </div>
          </div>
        </div>
        <div>
          <img loading="lazy" src={book} alt="img loading" />
        </div>
      </div>
      <div className="md:hidden  grid grid-cols-1">
        <div className="font-sans text-xl flex flex-col gap-7 ">
          <div>
            <p className="flex justify-center">After all, why wait another </p>
            <p className="flex justify-center"> decade for results?</p>
          </div>
          <div>
            <p className="flex justify-center">
              Seize the opportunity now with{" "}
            </p>
            <p className="flex justify-center">
              Sellerkin, and&nbsp;<strong> just a week from today,</strong>{" "}
            </p>

            <p className="flex justify-center gap-2">
              {" "}
              you'll witness the <strong>transformation </strong>{" "}
            </p>
            <p className="flex justify-center">you've been longing for.</p>
          </div>
          <div className="flex justify-center">
            <img loading="lazy" src={book1} alt="img loading" />
          </div>
          <div>
            <p className="flex justify-center">
              Your dedication will shine as you{" "}
            </p>
            <p className="flex justify-center">
              uncover beloved Etsy products,{" "}
            </p>
            <p className="flex justify-center">
              <strong>making informed decisions </strong> &nbsp; that
            </p>
            <p className="flex justify-center"> align with your passion</p>
          </div>
          <div>
            <p className="flex justify-center">Your journey to success is </p>
            <p className="flex justify-center">
              underway, and it all starts today.
            </p>
          </div>
          <div className="flex flex-row  md:hidden justify-center mt-5 font-sans ">
            <div>
              <img
                loading="lazy"
                src={small_right}
                alt="left arrow"
                className="md:hidden relative top-7"
              ></img>
            </div>
            <Link
              to="pricing_table"
              spy={true}
              smooth={true}
              offset={20}
              duration={1000}
              className="cursor-pointer bg-custom-color-1 m-1 text-xl px-10 py-3 border-2 rounded-lg flex justify-center items-center flex-col text-white font-bold font-sans"
              style={{ transition: "all 0.3s" }}
            >
              <p className="mr-1 md:mr-1">Get Instant Access</p>
              <p>to SellerKin</p>
            </Link>

            <div>
              <img
                loading="lazy"
                src={small_left}
                alt="right arrow 2"
                className="md:hidden relative top-7"
              ></img>
            </div>
          </div>
          <div className="font-serif">
            <p className=" text-base flex justify-center">
              Soon, Prices are going to be increased{" "}
            </p>
          </div>
          <div className="md:flex  gap-4  ">
            <div className="flex justify-center mb-5">
              <img
                loading="lazy"
                src={visa1}
                alt="payment"
                className="w-52 md:w-auto"
              ></img>
            </div>
            <div className="flex justify-center mb-5 gap-3">
              <img
                loading="lazy"
                src={secure}
                alt="payment"
                className="relative mb-2"
              ></img>
              <img
                loading="lazy"
                src={days}
                alt="payment"
                className="relative mb-2"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fourteen;
