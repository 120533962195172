import React from "react";
import pin from "../img/Group 80.webp";
import face from "../img/Ellipse 69 (1).webp";
import vector from "../img/Vector.svg";
import group84 from "../img/Group 84.svg";
import group116 from "../img/Group 116.webp";

const Second = () => {
  return (
    <div>
      <div className="floating-box hidden md:block flex-col mt-20">
        <div className="flex items-center justify-center relative xl:bottom-16 md:bottom-10">
          <img
            loading="lazy"
            className="flex items-center justify-center md:w-9 md:h-12 xl:w-20 xl:h-24 w-8 "
            src={pin}
            alt="pin"
          ></img>
        </div>

        <div className="flex  gap-10 justify-evenly font-serif">
          <div className="flex   flex-col">
            <div className="xl:left-10 relative">
              <div className="flex gap-2">
                <img
                  loading="lazy"
                  className="xl:w-14 xl:h-14 md:w-12 md:h-12 "
                  src={face}
                  alt="img loading"
                ></img>
                <div className="flex flex-col xl:text-base md:text-[10px] relative xl:left-5 md:top-3">
                  <p>To: All Business Owners</p>
                  <p>
                    <strong>From the Desk of Amit Miglani</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col xl:gap-6 md:gap-2 xl:left-10 relative xl:text-base md:text-[10px] mt-8 font-serif">
              <div>
                <p>It's a Proven Fact... </p>
              </div>
              <div>
                <p className="font-serif">
                  Finding <b>WINNING PRODUCT</b> and SELLING it is{" "}
                </p>
                <p>
                  the <b>Key to Success on Etsy Marketplace.</b>
                </p>
              </div>
              <div>
                <p>
                  However, There are <b>9.86 Million Sellers on Etsy</b>
                </p>
              </div>
              <div>
                <p>
                  NOT everyone is successful{" "}
                  <strong>(Too much Competition)</strong>{" "}
                </p>
              </div>
              <div>
                <p>You might wonder why?</p>
              </div>
              <div>
                <p>
                  {" "}
                  ...because <strong>NO ONE IS DOING PROPER RESEARCH</strong>
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3 relative xl:left-10 xl:text-base md:text-[10px] mt-4 font-serif">
              <div className="flex gap-3">
                <p>
                  <img
                    loading="lazy"
                    className="w-4 h-4"
                    src={vector}
                    alt="dot"
                  ></img>
                </p>
                <p>
                  You make the <strong className="underline">product</strong>{" "}
                </p>
              </div>
              <div className="flex gap-3">
                <p>
                  <img
                    loading="lazy"
                    className="w-4 h-4"
                    src={vector}
                    alt="dot"
                  ></img>
                </p>
                <p>
                  {" "}
                  You <strong className="underline">list</strong> it down
                </p>
              </div>
              <div className="flex gap-3">
                <p>
                  <img
                    loading="lazy"
                    className="w-4 h-4"
                    src={vector}
                    alt="dot"
                  ></img>
                </p>
                <p>
                  {" "}
                  You do <strong className="underline">SEO</strong>{" "}
                </p>
              </div>
              <div className="flex gap-3">
                <p>
                  <img
                    loading="lazy"
                    className="w-4 h-4"
                    src={vector}
                    alt="dot"
                  ></img>
                </p>
                <p>
                  You do all the{" "}
                  <strong className="underline">hard work</strong>{" "}
                </p>
              </div>
            </div>
            <div className="flex flex-col xl:text-base md:text-[10px] relative xl:ml-10 mt-4 font-serif">
              <p>And hoping that you will start receiving traction</p>
              <p>
                and sales only to <b>find out ZERO Sales next day...</b>
              </p>
            </div>
          </div>
          <div>
            <img
              loading="lazy"
              className="xl:ml-10 relative top-10 md:w-64 md:h-72 xl:w-[416px] xl:h-[489px]"
              src={group84}
              alt="img loading"
            ></img>
          </div>
        </div>

        <div className="flex  justify-evenly mt-10 font-serif">
          <div>
            <img
              loading="lazy"
              className="md:w-64 xl:w-[416px] xl:h-[489px]"
              src={group116}
              alt="img loading"
            ></img>
          </div>
          <div className="flex flex-col lg:gap-4 md:gap-2 xl:text-base md:text-[10px]">
            <div>
              <p>
                You immediately{" "}
                <b className="underline italic">start having self doubts</b> ...
              </p>
            </div>
            <div>
              <p>
                NOT only on Your Products BUT <strong>on </strong>{" "}
                <strong className="underline">Yourself</strong> as well
              </p>
            </div>
            <div>
              <p>Your Confidence also starts shaking</p>
            </div>
            <div>
              <p>
                You Start imaging those <b className="underline">late nights</b>{" "}
                when you{" "}
              </p>
              <p>were perfecting your product</p>
            </div>
            <div>
              <p>
                Your <strong className="underline"> Countless tweaks</strong> to
                get every detail just right
              </p>
            </div>

            <div>
              <p>
                You made this amount of <b className="underline"> effort</b>{" "}
                just to have a
              </p>
              <p>
                <b>ZERO SALE SURPRISE</b>
              </p>
            </div>
            <div>
              <p>And most importantly your hope, it gives you a blow</p>
              <p>
                thinking <strong>how others make more sales on Etsy?</strong>
              </p>
            </div>
            <div>
              <p>
                Truth being told, there is a <b>Better Way</b> and let me
              </p>
              <p>share that with you...</p>
            </div>
            <div>
              <p>Well here is the Winning Formula...</p>
            </div>
          </div>
        </div>
      </div>

      <div className="floating-box  md:hidden flex flex-col font-serif">
        <div className="flex items-center justify-center relative bottom-8">
          <img
            loading="lazy"
            className="flex items-center justify-center w-8 h-9"
            src={pin}
            alt="pin"
          ></img>
        </div>

        <div className="flex gap-10 justify-evenly text-base">
          <div className="flex flex-col">
            <div className="-mt-5">
              <div className="flex flex-col gap-2 justify-center items-center">
                <img
                  loading="lazy"
                  className="w-14 h-14"
                  src={face}
                  alt="hello"
                ></img>
                <div className="flex flex-col text-base">
                  <p className="flex justify-center mt-5">
                    To: All Business Owners
                  </p>
                  <p>
                    <strong>From the Desk of Amit Miglani</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 mt-5 justify-center items-center text-base">
              <div>
                <p>It's a Proven Fact... </p>
              </div>
              <div>
                <p className="flex justify-center">
                  Finding &nbsp; <b>WINNING PRODUCT</b> &nbsp; and
                </p>
                <p className="flex justify-center">
                  {" "}
                  SELLING it is the &nbsp; <strong>Key to Success</strong>{" "}
                </p>
                <p className="flex justify-center">
                  {" "}
                  <b> on Etsy Marketplace.</b>
                </p>
              </div>
              <div>
                <p className="flex justify-center">
                  However, There are &nbsp; <b>9.86</b>
                </p>
                <p className="flex justify-center">
                  <b> Million Sellers on Etsy</b>
                </p>
              </div>
              <div>
                <p className="flex justify-center">
                  NOT everyone is successful
                </p>
                <p className="flex justify-center">
                  <strong>(Too much Competition)</strong>{" "}
                </p>
              </div>
              <div>
                <img
                  loading="lazy"
                  className=" w-64 h-64"
                  src={group84}
                  alt="gdfg"
                ></img>
              </div>
              <div>
                <p className="flex justify-center">You might wonder why?</p>
              </div>
              <div>
                <p className="flex justify-center">
                  {" "}
                  ...because &nbsp; <strong>NO ONE IS DOING </strong>
                </p>
                <p className="flex justify-center">
                  <strong> PROPER RESEARCH</strong>
                </p>
              </div>
              <div className="flex flex-col">
                <div className="flex gap-3 ">
                  <p>
                    <img
                      loading="lazy"
                      className="w-4 h-4 relative top-1"
                      src={vector}
                      alt="dot"
                    ></img>
                  </p>
                  <p className="flex justify-center">
                    You make the &nbsp;{" "}
                    <strong className="underline">product</strong>{" "}
                  </p>
                </div>
                <div className="flex gap-3">
                  <p>
                    <img
                      loading="lazy"
                      className="w-4 h-4 relative top-1"
                      src={vector}
                      alt="dot"
                    ></img>
                  </p>
                  <p className="flex justify-center">
                    {" "}
                    You &nbsp; <strong className="underline">list</strong>{" "}
                    &nbsp; it down
                  </p>
                </div>
                <div className="flex gap-3">
                  <p>
                    <img
                      loading="lazy"
                      className="w-4 h-4 relative top-1"
                      src={vector}
                      alt="dot"
                    ></img>
                  </p>
                  <p className="flex justify-center">
                    {" "}
                    You do &nbsp; <strong className="underline">
                      SEO
                    </strong>{" "}
                  </p>
                </div>
                <div className="flex gap-3">
                  <p>
                    <img
                      loading="lazy"
                      className="w-4 h-4 relative top-1"
                      src={vector}
                      alt="dot"
                    ></img>
                  </p>
                  <p className="flex justify-center">
                    You do all the &nbsp;
                    <strong className="underline">hard work</strong>{" "}
                  </p>
                </div>
              </div>

              <div className="flex flex-col relative  mt-4">
                <p className="flex justify-center">
                  And hoping that you will start{" "}
                </p>
                <p className="flex justify-center">
                  {" "}
                  receiving traction and sales only to
                </p>
                <p className="flex justify-center">
                  {" "}
                  <b>find out ZERO Sales next day...</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-center justify-center mt-10 text-base">
          <div className="">
            <img
              loading="lazy"
              className="w-64 h-64 flex justify-center items-center relative ml-10"
              src={group116}
              alt="dfsd"
            ></img>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <div>
              <p className="flex justify-center">
                You immediately&nbsp;<b className="underline italic">start </b>{" "}
              </p>
              <p className="flex justify-center underline font-semibold italic">
                {" "}
                having self doubts...
              </p>
            </div>
            <div>
              <p className="flex justify-center">NOT only on Your Products </p>
              <p className="flex justify-center">
                {" "}
                BUT on &nbsp; <strong className="unerline">
                  {" "}
                  Yourself
                </strong>{" "}
                &nbsp; as well
              </p>
            </div>
            <div>
              <p className="flex justify-center">Your Confidence also starts</p>
              <p className="flex justify-center">shaking</p>
            </div>
            <div>
              <p className="flex justify-center">
                You Start imaging those &nbsp; <b className="underline">late</b>
              </p>
              <p className="flex justify-center">
                {" "}
                <b className="underline">nights</b> &nbsp;when you were
              </p>
              <p className="flex justify-center"> perfecting your product</p>
            </div>
            <div>
              <p className="flex justify-center">
                Your &nbsp;{" "}
                <strong className="underline"> Countless tweaks</strong>{" "}
                &nbsp;to get{" "}
              </p>
              <p className="flex justify-center">every detail just right</p>
            </div>

            <div>
              <p className="flex justify-center">
                You made this amount of &nbsp;{" "}
                <b className="underline"> effort</b>{" "}
              </p>
              <p className="flex justify-center">just to have a</p>
              <p className="flex justify-center">
                <b>ZERO SALE SURPRISE</b>
              </p>
            </div>
            <div>
              <p className="flex justify-center">
                And most importantly your hope, it{" "}
              </p>
              <p className="flex justify-center"> gives you a blow</p>
              <p className="flex justify-center">
                thinking &nbsp; <strong>how </strong>{" "}
              </p>
              <p className="flex justify-center">
                {" "}
                <strong>others make more sales on Etsy?</strong>
              </p>
            </div>
            <div>
              <p className="flex justify-center">
                Truth being told, there is a{" "}
              </p>
              <p className="flex justify-center">
                {" "}
                <b>Better Way</b>&nbsp; and let me
              </p>
              <p className="flex justify-center">share that with you...</p>
            </div>
            <div>
              <p className="flex justify-center">Well here is the </p>
              <p className="flex justify-center">Winning Formula...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
