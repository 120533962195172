import First from "./First";
import Second from "./Second";
import Third from "./Third";
import Fourth from "./Fourth";
import Fifth from "./Fifth";
import Sixth from "./Sixth";
import Seventh from "./Seventh";
import Eight from "./Eight";
import Nine from "./Nine";
import Tenth from "./Tenth";
import Eleven from "./Eleven";
import Twelve from "./Twelve";
import Thirteen from "./Thirteen";
import Fourteen from "./Fourteen";
import Fifteen from "./Fifteen";
import Sixteen from "./Sixteen";
import Seventeen from "./Seventeen";
import Eighteen from "./Eighteen";
import Footer from "./Footer";

function Home() {
  return (
    <div>
      <First></First>
      <Second></Second>
      <Third></Third>
      <Fourth></Fourth>

      <Fifth></Fifth>

      <Sixth></Sixth>
      <Seventh></Seventh>

      <Eight></Eight>

      <Nine></Nine>

      <Tenth></Tenth>

      <Eleven></Eleven>

      <Twelve></Twelve>

      <Thirteen></Thirteen>

      <Fourteen></Fourteen>

      <Fifteen></Fifteen>

      <Sixteen></Sixteen>

      <Seventeen></Seventeen>

      <Eighteen></Eighteen>
      <Footer></Footer>
    </div>
  );
}

export default Home;
