import React from "react";
import Home from "./components/home";
// import Contactus from "./components/Contactus";
// import Termsofservice from "./components/TermsOfService";
// import Privacy from "./components/Privacy";
// import Cancellation from "./components/Cancellation";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PaymentPage from "./Page/payment";
import Affiliate from "./components/Affiliate";
// import Shipping from "./components/Shipping";
const LazyContactus = React.lazy(() => import("./components/Contactus"));
const LazyTerms = React.lazy(() => import("./components/TermsOfService"));
const LazyCancel = React.lazy(() => import("./components/Cancellation"));
const LazyPrivacy = React.lazy(() => import("./components/Privacy"));
const LazyShipping = React.lazy(() => import("./components/Shipping"));

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/affiliates",
      element: (
        <>
          <Affiliate />
        </>
      ),
    },

    {
      path: "/privacy-policy",
      element: (
        <React.Suspense fallback={<p>Loading...</p>}>
          <LazyPrivacy />
        </React.Suspense>
      ),
    },
    {
      path: "/terms-of-service",
      element: (
        <React.Suspense fallback={<p>Loading...</p>}>
          <LazyTerms />
        </React.Suspense>
      ),
    },
    {
      path: "/cancellation",
      element: (
        <React.Suspense fallback={<p>Loading...</p>}>
          <LazyCancel />
        </React.Suspense>
      ),
    },
    {
      path: "/contactus",
      element: (
        <React.Suspense fallback={<p>Loading...</p>}>
          <LazyContactus />
        </React.Suspense>
      ),
    },
    {
      path: "shipping-policy",
      element: (
        <React.Suspense fallback={<p>Loading...</p>}>
          <LazyShipping />
        </React.Suspense>
      ),
    },
    {
      path: "/paymentBasic",
      element: <PaymentPage plan="Basic" />,
    },
    {
      path: "/paymentStandard",
      element: <PaymentPage plan="Standard" />,
    },
    {
      path: "/paymentPro",
      element: <PaymentPage plan="Pro" />,
    },
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
