import React, { useState } from "react";
import logo from "../img/logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
function Affiliate() {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const ScrollToPrice = () => {
    navigate("/");
    scroll.scrollTo("pricing_section", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const closeMenu = () => setShowMenu(false);
  const menu = [
    {
      name: "How it works",
      key: 1,
      id: "how_it_works",
    },
    {
      name: "Testimonials",
      key: 2,
      id: "testimonials",
    },
    {
      name: "Pricing",
      key: 3,
      id: "pricing_table",
    },
  ];
  return (
    <div className="bg-custom-color pb-10 h-[900px]">
      <nav className="flex md:justify-around justify-between md:px-10 px-3 py-6 ">
        <div className="flex gap-2" onClick={() => navigate("/")}>
          <img
            loading="lazy"
            src={logo}
            alt="SellerKin logo"
            className="relative -mt-4"
          ></img>
          <span className="text-3xl font-bold relative xl:bottom-2 cursor-default ">
            SellerKin
          </span>
        </div>
        {/* ul list  */}
        <div className="flex gap-8 font-serif">
          <div>
            <ul className=" justify-center list-none ml-4 hidden xl:flex space-x-5">
              <li className="text-lg text-black hover:text-orange-400 transition-all cursor-pointer">
                <Link
                  to="how_it_works"
                  spy={true}
                  smooth={true}
                  offset={20}
                  duration={1000}
                  onClick={() => navigate("/")}
                  style={{ transition: "all 0.3s" }}
                >
                  How it Works
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="testimonials"
                  spy={true}
                  smooth={true}
                  offset={20}
                  duration={1000}
                  onClick={() => navigate("/")}
                  className="text-lg text-black hover:text-orange-400 transition-all cursor-pointer"
                  style={{ transition: "all 0.3s" }}
                >
                  Testimonials
                </Link>
              </li>
              <li className="text-lg">
                <Link
                  to="pricing_table"
                  spy={true}
                  smooth={true}
                  offset={1}
                  duration={1000}
                  onClick={() => navigate("/")}
                  className="text-lg text-black hover:text-orange-400 transition-all cursor-pointer"
                  style={{ transition: "all 0.3s" }}
                >
                  Pricing
                </Link>
              </li>
              <li className="text-lg">
                <a
                  href="/affiliates"
                  className="text-lg text-black hover:text-orange-400 transition-all cursor-pointer"
                  style={{ transition: "all 0.3s" }}
                >
                  Affiliates
                </a>
              </li>
            </ul>
          </div>

          {/* button */}
          <div className="space-x-5 hidden md:flex md:left-20 md:relative  xl:static -mt-2 md:justify-end">
            {/* <button className="border-2 rounded border-custom-color-1 bg-custom-color-1 font-medium px-2.5 py-0.5 text-white xl:text-xl md:text-sm"> */}
            <Link
              to="pricing_table"
              spy={true}
              smooth={true}
              offset={20}
              duration={1000}
              onClick={() => navigate("/")}
              className="cursor-pointer flex items-center border-2 rounded border-custom-color-1 bg-custom-color-1 font-medium px-2.5 py-0.5 text-white xl:text-xl md:text-sm"
              style={{ transition: "all 0.3s" }}
            >
              Get Started
            </Link>
            <a
              href="https://app.sellerkin.com/freetrial"
              target="_blank"
              className="cursor-pointer flex items-center border-2 rounded border-custom-color-1 bg-custom-color-1 font-medium px-2.5 py-0.5 text-white xl:text-xl md:text-sm"
            >
              Free Trial
            </a>
          </div>
        </div>

        <div className="hamburger-menu md:hidden block font-serif index ">
          <a href="#" onClick={() => setShowMenu(!showMenu)}>
            <GiHamburgerMenu />
            {showMenu ? (
              <div className="absolute mt-3 w-32 h-28 bg-white rounded-md border-[1px] p-3 border-black/70 translate-x-[-105px]">
                <div className="flex flex-col gap-2">
                  {menu.map((item) => (
                    <Link
                      key={item.key}
                      to={item.id}
                      spy={true}
                      smooth={true}
                      offset={20}
                      duration={1000}
                      onClick={() => {
                        closeMenu();
                      }}
                      className="cursor-pointer text-black hover:text-orange-400 transition-all cursor-pointer"
                      style={{ transition: "all 0.3s" }}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}
          </a>
        </div>
      </nav>
      <div className="w-8/12 mx-auto mt-16 flex flex-col gap-8 ">
        <div className="space-y-6">
          <h1 className="font-bold text-2xl">Become a SellerKin Affiliate</h1>
          <h3 className="font-semibold text-lg">Why Promote SellerKin ?</h3>
        </div>
        <div className="space-y-2 font-medium">
          <p>
            <span className="font-bold"> 1. Unique Value:</span> SellerKin is
            the only tool offering a lifetime deal, unlike other monthly
            subscription tools.
          </p>

          <p>
            <span className="font-bold"> 2. High Conversion:</span> Our features
            like Product Finder, Listing Analyzer Shop Analyzer provide crucial
            insights, leading to high customer satisfaction and conversions.
          </p>

          <p>
            <span className="font-bold"> 3. Generous Commissions:</span> Earn
            40% commissions on every sale you generate.
          </p>
        </div>
        <div className="space-y-4">
          <h3 className="font-bold text-2xl"> How to Get Started</h3>
          <p className="font-medium text-lg">
            Please send an email to{" "}
            <span className="text-[#F95700]">sellerkin@gmail.com</span> to join
            as an affiliate, and we'll share the further process.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Affiliate;
